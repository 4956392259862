<template>
	<div class="user-settings">
		<section class="data-table user-table">
			<div class="filters">
				<div class="filter"></div>
				<div class="sort-search">
					<button class="button" @click="create_user">
						Neuen Benutzer hinzufügen
					</button>
				</div>
			</div>
			<table class="table">
				<thead>
					<th>Name</th>
					<th>E-Mail Adresse</th>
					<th>Rolle</th>
					<th>Aktionen</th>
				</thead>
				<tbody>
					<tr v-for="(user, idx) in users" :key="user.UUID">
						<td>{{ user.name }}</td>
						<td>{{ user.email }}</td>
						<td>{{ user.role.friendly_name }}</td>
						<td>
							<button class="option" @click.prevent="editUser(idx, user.UUID)">
								<unicon
									name="pen"
									:fill="'#65c368'"
									icon-style="line"
									width="21"
									height="21"
								/>
							</button>
							<button
								class="option"
								:disabled="user.UUID === $store.getters.getUser.id"
								@click.prevent="deleteUser(idx, user.UUID)"
							>
								<unicon
									name="times"
									:fill="'#006a9f'"
									icon-style="line"
									width="21"
									height="21"
								/>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
		<edit-user-modal
			v-if="show_edit_user_modal"
			:user="selected_user"
			:new_user="is_new_user_selected"
			@close="reload"
			@reload="reload"
		></edit-user-modal>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
	components: {
		editUserModal: () =>
			import('@/components/layout/modals/edit-user-modal.vue')
	},
	data() {
		return {
			users: [],
			show_edit_user_modal: false,
			selected_user: null,
			is_new_user_selected: false
		};
	},
	mounted() {
		this.getAllUsers();
	},
	methods: {
		async getAllUsers() {
			const usersReposnse = await this.$api.post(
				'/account/get_all_users',
				{},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.users = usersReposnse.data;
		},
		editUser(idx, UUID) {
			this.selected_user = this.users[idx];
			this.show_edit_user_modal = true;
		},
		async deleteUser(idx, UUID) {
			const result = await Swal.fire({
				title: 'Sind Sie sicher? ',
				text: `Soll der Nutzer ${this.users[idx].name} wirklich gelöscht werden?`,
				icon: 'warning',
				showCloseButton: false,
				showCancelButton: true,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				confirmButtonText: 'Ja!',
				cancelButtonText: 'Abbrechen'
			});
			if (result.isConfirmed) {
				const deleteReposnse = await this.$api.post(
					'/auth/delete_user',
					{ UUID: UUID },
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				if (deleteReposnse.status === 200) {
					this.reload();
				}
			}
		},
		create_user() {
			this.is_new_user_selected = true;
			this.show_edit_user_modal = true;
		},
		reload() {
			this.show_edit_user_modal = false;
			this.is_new_user_selected = false;
			this.getAllUsers();
		}
	}
};
</script>

<style lang="scss">
.user-settings {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	box-shadow: 0 0 20px -12px #394150;
	padding: 3rem;
	background-color: $background-color;
	justify-content: center;
	align-items: flex-start;
	justify-items: center;
}
</style>
